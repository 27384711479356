import React from 'react'
import Layout from '../components/layout'
import './publications.css'
/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.app/gatsby-image
 * - `StaticQuery`: https://gatsby.app/staticquery
 */

const Publications = () => (
  <Layout>
    <div className="mdl-grid">
      <div className="mdl-cell mdl-cell--12-col" id="Articles">
        <div className="mdl-card mdl-shadow--2dp" style={{ width: 'auto' }}>
          <div className="mdl-card__title mdl-card--expand">
            <div className="mdl-cell mdl-cell--12-col">
              <h2 className="page-header" style={{ padding: '5px' }}>
                Published Articles
              </h2>

              <p>
                Gary has written numerous articles for professional magazines
                and journals. A sample of the articles is shown below in several
                major claims management categories. Gary’s articles in Claims
                magazine can also be found at{' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://www.PropertyCasualty360.com/author/gary-jennings"
                >
                  PropertyCasualty360&deg;.
                </a>
              </p>
              <ul>
                <li>
                  <strong>
                    <u>General Topics</u>
                  </strong>
                </li>
                <ul>
                  <li>
                    "Plant Closing: Lock the Doors and Throw Away the Key", Risk
                    Management, Apr. 1996, pp. 83 – 89.
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://www.propertycasualty360.com/2013/12/03/8-corporate-claims-management-fundamentals"
                    >
                      "8 Corporate Claims Management Fundamentals"
                    </a>
                    , Claims magazine, Dec. 2013, and PropertyCasualty360
                    (PC360).{' '}
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://www.propertycasualty360.com/2014/02/20/using-more-of-whats-already-in-the-box"
                    >
                      "Using More of What’s Already ‘In the Box’"
                    </a>
                    , Claims magazine, Mar. 2014, and PC360.
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://www.propertycasualty360.com/2014/05/01/dont-swim-with-the-shirks"
                    >
                      "Don’t Swim With The Shirks"
                    </a>
                    , Claims magazine, May 2014, and PC360.
                  </li>
                  <img
                    className="aboutImg"
                    style={{ maxWidth: '150px', float: 'right' }}
                    src="/CLAIMS_MAGAZINE_COVER_APRIL2016.gif"
                    alt="Claims Magazine cover Issue April 2016"
                  />
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://www.propertycasualty360.com/2014/07/01/avoiding-malfunction-junction-in-claims-management"
                    >
                      "Avoiding ‘Malfunction Junction’ in Claims Management"
                    </a>
                    , Claims magazine, Jul. 2014, and PC360.
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://www.propertycasualty360.com/2014/09/23/stuck-on-an-unusual-workers-comp-claim-dont-give-u"
                    >
                      "Stuck on an unusual workers’ comp claim? Don’t give up
                      until you read this."
                    </a>{' '}
                    Claims magazine, Oct. 2014, and PC360.
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://www.propertycasualty360.com/2014/10/13/the-value-of-accountability-in-claims-management"
                    >
                      "The Value of Accountability in Claims Management"
                    </a>
                    , Claims magazine, Nov. 2014, and PC360.
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://www.propertycasualty360.com/2016/02/24/dont-run-your-claims-management-on-autopilot"
                    >
                      "Don’t Run Your Claims Management on Autopilot"
                    </a>
                    , Claims magazine, Feb. 2016, and PC360.
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://www.propertycasualty360.com/2016/04/19/theres-a-coverage-gap-with-that-app"
                    >
                      "There’s a Coverage Gap with that App"
                    </a>
                    , Claims magazine, Apr. 2016, and PC360.
                  </li>
                  <li>
                    <iframe
                      title="Sound Cloud Player track 297538843"
                      width="80%"
                      height="166"
                      scrolling="no"
                      frameBorder="no"
                      src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/297538843&amp;color=ff5500&amp;auto_play=false&amp;hide_related=false&amp;show_comments=true&amp;show_user=true&amp;show_reposts=false"
                    />
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://blog.primacentral.org/content/workers-compensation-return-work-programs-3-reasons-failure-and-how-overcome-them-part-1"
                    >
                      "Workers' Compensation & Return to Work Programs: 3
                      Reasons for Failure and How to Overcome Them (Part 1)",
                      Public Risk Management Association, Jan 2017
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://blog.primacentral.org/content/workers-compensation-return-work-programs-3-reasons-failure-and-how-overcome-them-part-2"
                    >
                      "Workers' Compensation & Return to Work Programs: 3
                      Reasons for Failure and How to Overcome Them (Part 2)",
                      Public Risk Management Association, Mar 2017
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://blog.primacentral.org/content/loss-control-claims-perspective"
                    >
                      "Loss Control: A Claims Perspective", Public Risk
                      Management Association, Aug 2017
                    </a>
                  </li>
                </ul>
                <li>
                  <strong>
                    <u>Catastrophes</u>
                  </strong>
                </li>
                <ul>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://www.propertycasualty360.com/2015/04/08/planning-for-catastrophes"
                    >
                      "Planning for Catastrophes"
                    </a>
                    , Claims magazine, Apr. 2015, and PC360.
                  </li>
                </ul>
                <li>
                  <strong>
                    <u>Claims Audits</u>
                  </strong>
                </li>
                <ul>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://www.propertycasualty360.com/2013/08/14/claims-auditing-is-a-springboard-to-cost-reduction"
                    >
                      "Claims Auditing is a Springboard to Cost Reduction"
                    </a>
                    , Claims magazine, Sept. 2013, Workers’ Comp Watch", Aug
                    2013, and PC360.{' '}
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://www.propertycasualty360.com/2013/09/24/fast-track-resolution-indemnification-with-propert"
                    >
                      "Fast-Track Resolution, Indemnification with Property
                      Claims Audits"
                    </a>
                    , Claims magazine, Oct. 2013, and PC360.{' '}
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://www.propertycasualty360.com/2013/10/16/complex-audits-the-split-personality-of-liability"
                    >
                      "The ‘Split Personality’ of Liability Claims"
                    </a>
                    , Claims magazine, Nov. 2013, and PC360.
                  </li>
                </ul>
                <img
                  className="aboutImg"
                  style={{ maxWidth: '150px', float: 'right' }}
                  src="/PUBLIC_RISK_MAGAZINE_COVER.jpg"
                  alt="Public Risk Magazine Cover"
                />

                <li>
                  <strong>
                    <u>Cost Allocation</u>
                  </strong>
                </li>
                <ul>
                  <li>
                    "Insurance and Claims Cost Allocation", The Journal of
                    Workers’ Compensation, Fall 2002, Vol.12, No.1, pp. 30 – 42.
                  </li>
                </ul>

                <li>
                  <strong>
                    <u>Fraud</u>
                  </strong>
                </li>
                <ul>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://www.propertycasualty360.com/2014/11/11/here-are-the-3-most-common-types-of-workers-comp-f"
                    >
                      "3 Most Common Types of Workers’ Comp Fraud"
                    </a>
                    , Claims magazine Dec. 2014, BenefitsPro on 11/13/14, and
                    PC360.
                  </li>
                </ul>
                <li>
                  <strong>
                    <u>Litigation Management</u>
                  </strong>
                </li>
                <ul>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://www.propertycasualty360.com/2016/07/01/a-proactive-approach-to-reducing-claims-litigation"
                    >
                      "A Proactive Approach to Reducing Claims Litigation"
                    </a>
                    , Claims magazine, Jul. 2016, and PC360.
                  </li>
                </ul>
                <li>
                  <strong>
                    <u>Processes & Procedures</u>
                  </strong>
                </li>
                <ul>
                  <li>
                    "Claim Process Flows: How They Affect Claims Outcomes,"
                    Public Risk magazine, March 2014.
                  </li>
                </ul>

                <li>
                  <strong>
                    <u>Reserving</u>
                  </strong>
                </li>

                <ul>
                  <li>
                    "A Risk Manager’s Guide to Claims Reserving", The John Liner
                    Review, Summer 2004, Vol. 18, No. 2, pp. 62 – 73.
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://www.propertycasualty360.com/2010/02/01/under-pressure"
                    >
                      "Under Pressure: The Temptation to Reduce Reserves Can
                      Increase in a Poor Economy"
                    </a>
                    , Claims magazine, Feb. 2010, and PC360.
                  </li>
                </ul>

                <li>
                  <strong>
                    <u>Self Administration</u>
                  </strong>
                </li>
                <ul>
                  <li>
                    "The Pros and Cons of Self-Administration", The Journal of
                    Workers’ Compensation, Spring 2003, Vol. 12, No. 3, pp. 50 –
                    62.
                  </li>
                </ul>
                <li>
                  <strong>
                    <u>Systems & Data Analysis</u>
                  </strong>
                </li>
                <img
                  className="aboutImg"
                  style={{ maxWidth: '150px', float: 'right' }}
                  src="/JOHN_LINER_REVIEW.png"
                  alt="John Liner Review cover"
                />
                <ul>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://www.propertycasualty360.com/2008/11/26/great-expectations"
                    >
                      "Great Expectations: Successful System Implementations
                      Require Clear Communication"
                    </a>
                    , Claims magazine, Dec. 2008, and in PC360
                  </li>
                  <li>
                    "Abundant Claim Information: Boon or Bane?" The John Liner
                    Review, Summer 2010, pp. 47 – 51.
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://www.propertycasualty360.com/2014/01/14/to-foster-claims-excellence-begin-with-the-right-m"
                    >
                      "To Foster Claims Excellence, Begin with the Right
                      Metrics"
                    </a>
                    , Claims magazine, Feb. 2014, and PC360.
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://www.propertycasualty360.com/2014/07/30/its-the-data-stupid"
                    >
                      "It’s the Data, Stupid!"
                    </a>{' '}
                    Claims magazine Aug. 2014, and PC360
                  </li>
                  <li>
                    "Metrics for Improved Claims Outcomes", Public Risk
                    magazine, Sep. 2014
                  </li>
                </ul>
                <li>
                  <strong>
                    <u>TPA Evaluation & Selection</u>
                  </strong>
                </li>
                <ul>
                  <li>
                    "The ABCs of TPAs: Selecting Your Company’s Third Party
                    Administrator", The Journal of Workers’ Compensation, Summer
                    2004, Vol. 13, No. 4, pp. 61 – 70.
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://www.propertycasualty360.com/2013/12/20/top-10-characteristics-to-look-for-in-a-claims-adm"
                    >
                      "Top 10 Characteristics to Look for in Your Claims
                      Administrator"
                    </a>
                    , Claims magazine, Jan. 2014, and PC360.
                  </li>
                </ul>
                <li>
                  <strong>
                    <u>Telecommuting</u>
                  </strong>
                </li>
                <ul>
                  <li>"Out of Site", Risk & Insurance, Aug. 1994, p. 22</li>
                </ul>
                <li>
                  <strong>
                    <u>Transportation Industry</u>
                  </strong>
                </li>
                <ul>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://www.propertycasualty360.com/2015/01/26/5-solutions-for-workers-comp-challenges-in-the-tru"
                    >
                      "5 Solutions for Workers’ Comp Challenges in the Trucking
                      Industry"
                    </a>
                    , Claims magazine, Feb. 2015, and PC360.
                  </li>
                </ul>
                <li>
                  <strong>
                    <u>Training</u>
                  </strong>
                </li>
                <ul>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://www.propertycasualty360.com/2014/12/29/claims-ethics"
                    >
                      "Claims Ethics"
                    </a>{' '}
                    Claims magazine, Jan. 2015, and PC360
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://www.propertycasualty360.com/2016/01/12/here-are-4-ways-to-give-your-claims-professionals"
                    >
                      "Practice Makes Perfect – The Role of Employee Training"
                    </a>
                    , Claims magazine, Jan. 2016, and PC360.
                  </li>
                </ul>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)
export default Publications
